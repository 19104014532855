@charset 'UTF-8';

.header {
	position: relative;
	left: auto;
	top: auto;
	z-index: 99999;
	width: 100%;
	// @include add_prefix(box-shadow, 0px 0px 5px 1px rgba(0,0,0,0.1));
	.inHeader {
		max-width: $widthcm;
		margin: 0 auto;
		position: relative;
		z-index: 2000;
		background: #fff;
	}
	#logo {
		position: absolute;
		left: 0;
		top: 15px;
		z-index: 1000;
		@include add_prefix(transition, 0.3s ease all);
		img {
			display: block;
			width: 139px;
			height: 34px;
			@include add_prefix(transition, 0.3s ease all);
		}
	}
	@include max-screen(767px) {
		#logo {
			top: 15px;
			img {
				width: 80px;
				height: 20px;
			}
		}
		.hNavi {
			display: none;
		}
	}
}

.fixHeader {
	padding-top: 130px;
	@include max-screen(767px) {
		padding-top: 50px;
	}
	.header {
		position: fixed;
		left: 0;
		top: 0;
		padding: 15px 15px;
		#logo {
			top: 72px;
			img {
				width: 108px;
				height: 26px;
			}
		}
		.hNavi {
			margin-top: -70px;
			margin-bottom: 25px;
		}
		@include max-screen(767px) {
			#logo {
				top: 0;
				img {
					width: 80px;
					height: 20px;
				}
			}
			.hNavi {
				margin: 0 0 25px;
			}
		}
	}
}

.layerMenu {
	text-align: center;
	position: fixed;
	right: -100%;
	top: 0;
	opacity: 0;
	background: #434343;
	width: 50%;
	height: 100%;
	z-index: 99998;
	@include add_prefix(transition, 0.8s ease all);
	&.is-nav-open {
		right: 0;
		opacity: 1;
	}
	.inLayer {
		position: relative;
		height: 100%;
	}
	.gNavi {
		position: absolute;
		top: 50%;
		left: 20px;
		right: 20px;
		-moz-transform: translateY(-50%);
	    -webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    transform: translateY(-50%);
		max-width: 490px;
		margin: auto;
		width: calc(100% - 30px);
		> li {
			vertical-align: top;
			font-weight: bold;
			position: relative;
			font-size: 26px;
			text-align: left;
			@include max-screen(1024px) {
				font-size: 24px;
			}
			> a {
				display: block;
				padding: 20px 0;
				color: #fff;
				font-family: $plus;
				position: relative;
				border-bottom: 1px solid rgba(255,255,255,0.15);
				&:hover {
					opacity: 1;
					color: $green1;
				}
			}

			&:last-child {
				a {
					border-bottom: 0;
				}
			}
			&.active {
				a {
					color: $green1;
				}
			}
		}
	}
	.close_layer {
		display: none;
	}
	@include SP {
		width: 100%;
		.inLayer {
			position: relative;
			height: 90%;
			padding: 0 0 30px 0;
			overflow: auto;
		}
		.gNavi {
			left: 0;
			right: 0;
			position: relative;
			top: 45px;
			-moz-transform: translateY(0%);
		    -webkit-transform: translateY(0%);
		    -ms-transform: translateY(0%);
		    transform: translateY(0%);
			> li {
				text-align: center;
				font-size: 19px;
				a {
					padding: 24px 0;
				}
			}
		}
		.close_layer {
			display: block;
			padding: 12px 0 10px;
			background: #BFBFBF;
			color: #fff;
			font-weight: bold;
			letter-spacing: 2px;
			text-align: center;
			cursor: pointer;
		}
	}
}

.layerOn {
	//overflow: hidden;
	.layerMenu {
		right: 0;
	}
}

#overlay {
	position: fixed;
	content: "";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 9994;
	&.is-active {
		display: block;
	}
}

.hamberger {
	@include border-radius(10px);
	position: fixed;
	right: 35px;
	top: 35px;
	z-index: 100000;
	width: 70px;
	height: 70px;
	background: $green1;
	padding: 26px 10px 0 10px;
	line-height: 1;
	font-size: 0;
	text-align: center;
	cursor: pointer;
	span {
		margin: auto;
		display: block;
		width: 22px;
		height: 2px;
		margin-bottom: 6px;
		background: #fff;
		@include add_prefix(transition, 0.3s ease all);
	}
	&.is-active {
		span {
			width: 31px;
		}
		.ham { @include transform_c(rotate(45deg) translateY(6px) translateX(6px)); }
		.ber { @include transform_c(rotate(-45deg)); }
		.ger { display: none; }
	}
	@include SP {
		right: 15px;
		top: 15px;
	}
}