@charset "UTF-8";

/* ------------- Background -------------- */
.bg-green {
  background: $green1;
}
.bg-gray {
  background: $gray;
}
.bg-skew {
  position: relative;
  background: $green1;
  padding: 140px 0;
  overflow: hidden;
  @include transform(skew(0deg, -9.5deg));
  margin-top: -140px;
  &.bg-bubble1 {
    .bg-skew-content:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      width: 100%;
      height: calc(100% + 440px);
      background: url(../img/common/bg-bubble1.png) no-repeat center top;
      background-size: cover;
      margin-top: -60px;
    }
  }
  &.bg-gray1 {
    background: $gray;
  } 
  &.bg-bubble2 {
    margin-top: -20px;
    padding: 112px 0 210px 0;
    .bg-skew-content:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      width: 100%;
      height: calc(100% + 600px);
      background: $gray url(../img/common/bg-bubble2.png) no-repeat center top;
      background-size: cover;
      margin-top: -190px;
    }
  }
  &.bg-bubble3 {
    margin-top: 0;
    margin-bottom: -180px;
    padding: 120px 0 183px 0;
    .bg-skew-content:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      width: 100%;
      height: calc(100% + 400px);
      background: url(../img/common/bg-bubble3.png) no-repeat center top;
      background-size: cover;
      margin-top: -190px;
    }
  }
  &.bg-bubble4 {
    margin-bottom: 0;
    padding: 300px 0 90px 0;
    .bg-skew-content:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      width: 100%;
      height: calc(100% + 400px);
      background: url(../img/common/bg-bubble4.png) no-repeat center top;
      background-size: cover;
      margin-top: -190px;
    }
  }

  &-content {
    position: relative;
    @include transform(skew(0deg, 9.5deg));
    .wcm {
      position: relative;
      z-index: 999;
    }
  }
}
.bg-bubble2 {
  
}
.coming_soon {
  .bg-skew {
    &.bg-bubble2 {
      padding: 135px 0 125px 0;
      .bg-skew-content:after {
        background: $gray url(../img/common/bg-bubble5.png) no-repeat center top;
        background-size: cover;
      }
      .cmn-title.black .ttl {
        line-height: 1.5;
        font-size: 25px;
        small {
          margin-bottom: 15px;
        }
      }
      @include SP {
        .cmn-title.black .ttl {
          font-size: 18px;
        }
        padding: 80px 0 70px 0;
      }
    }
    &.bg-bubble4 {
      .bg-skew-content:after {
        background: $gray url(../img/common/bg-bubble6.png) no-repeat center top;
        background-size: cover;
      }
    }
  }
}
@include SP  {
  .bg-skew {
    padding: 58px 0;
    @include transform(skew(0deg, -9.5deg));
    margin-top: 0;
    &.bg-bubble1 {
      .bg-skew-content:after {
        height: calc(100% + 400px);
        background: $green1 url(../img/common/bg-bubble1-sp.png) no-repeat center top;
        background-size: 100% auto;
        margin-top: -30px;
      }
    }
    &.bg-gray1 {
      background: $gray;
    } 
    &.bg-bubble2 {
      margin-top: 0;
      padding: 78px 0 62px 0;
      .bg-skew-content:after {
        display: none;
      }
    }
    &.bg-bubble3 {
      margin-bottom: -75px;
      padding: 53px 0 37px 0;
      .bg-skew-content:after {
        display: none;
      }
    }
    &.bg-bubble4 {
      margin-top: 0;
      padding: 152px 0 45px 0;
      .bg-skew-content:after {
        display: none;
      }
    }

    &-content {
      position: relative;
      z-index: 9;
      @include transform(skew(0deg, 9.5deg));
      .wcm {
        position: relative;
        z-index: 999;
      }
    }
  }
}
/* --------------- Button ---------------- */


/* --------------- Title ----------------- */
.cmn-title {
  text-align: center;
  position: relative;
  margin-bottom: 70px;
  .ttl {
    font-size: 34px;
    color: #fff;
    font-family: $plus;
    font-weight: bold;
    small {
      font-size: 18px;
      display: block;
      font-weight: bold;
      margin-bottom: 12px;
      font-family: $comfortaa;
      -webkit-font-smoothing: auto;
      letter-spacing: 3px;
    }
  }
  &.black {
    .ttl {
      color: $txtmain;
    }
  }
}
.cmn-title2 {
  text-align: center;
  position: relative;
  z-index: 99;
  margin-bottom: 25px;
  .ttl {
    font-size: 34px;
    color: $txtmain;
    font-family: $plus;
    font-weight: bold;
    small {
      color: #DAB87B;
      font-size: 18px;
      display: block;
      font-weight: bold;
      margin-bottom: 8px;
      font-family: $comfortaa;
      -webkit-font-smoothing: auto;
      letter-spacing: 3px;
    }
  }
}
@include SP {
  .cmn-title {
    margin-bottom: 45px;
    line-height: 1.52;
    .ttl {
      small {
        margin-bottom: 7px;
        letter-spacing: 3px;
      }
      @include max-screen(320px) {
        @include font-size(30);
      }
    }
  }
  .cmn-title2 {
    .ttl {
      small {
        margin-bottom: 6px;
      }
    }
    @include max-screen(320px) {
      .ttl {
        @include font-size(30);
      }
    }
  }
}
/* ---------------- Text ----------------- */
.txt-green {
  	color: $green1;
}
.txt-yellow {
	color: $yellow;
}
.txt-yellow2 {
  color: $yellow2;
}
.txt-pink {
  color: $pink;
}
/* ------------ Block common ------------- */


/* -------------- Animation -------------- */ 
.line-scroll {
    position: absolute;
	content: "";
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	height: 36px;
	width: 1px;
}
.line-scroll em {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1px;
    height: 36px;
    overflow: hidden;
}
.line-scroll em::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scrollBtnAnime;
  animation-name: scrollBtnAnime;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%)
}

@keyframes scrollBtnAnime {
  0% {
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }
  100% {
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
  }
}

@-webkit-keyframes scrollBtnAnime {
  0% {
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }
  100% {
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
  }
}


.fade-to-right {
	opacity: 1;
	animation-name: fadeRightOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeRightOpacity {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-80px);
		-moz-transform: translateX(-80px);
		transform: translateX(-80px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0px);
		-moz-transform: translateX(0px);
		transform: translateX(0px);
	}
}


.fade-to-left {
	opacity: 1;
	animation-name: fadeLeftOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeLeftOpacity {
	0% {
		opacity: 0;
		-webkit-transform: translateX(80px);
		-moz-transform: translateX(80px);
		transform: translateX(80px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0px);
		-moz-transform: translateX(0px);
		transform: translateX(0px);
	}
}
.invTop { 
  -webkit-transition: -webkit-transform 1s, opacity 0.6s;
  -moz-transition: -moz-transform 1s, opacity 0.6s;
  transition: transform 1s, opacity 0.6s;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  transform: translateY(100px);
}
.invTop.is-show {
  opacity: 1;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
  -moz-transform: matrix(1, 0, 0, 1, 0, 0);
  transform: matrix(1, 0, 0, 1, 0, 0);
}


// .inviewfadeInUp {
//     opacity: 0;
//     transition: .5s;
// }
// .inviewfadeInUp.fp-completely,.inviewfadeInUp.active {
//     opacity: 1.0;
// }
// @include SP {
//   .inviewfadeInUp.active1 {
//     opacity: 1.0;
//     -webkit-transition: opacity 0.3s ease-in-out;
//     -moz-transition: opacity 0.3s ease-in-out;
//     transition: opacity 0.3s ease-in-out;
//   }
// }
// .fadeInUp1 {
//     opacity: 0;
//     transform: translate(0, 50px);
//     -webkit-transform: translate(0, 50px);
//     transition: 0.7s;
// }
// .fp-completely .fadeInUp1 {
//     opacity: 1.0;
//     transform: translate(0, 0);
//     -webkit-transform: translate(0, 0);
// }
// .fp-table.active .fadeInUp1 {
//     opacity: 1.0;
//     transform: translate(0, 0);
//     -webkit-transform: translate(0, 0);
// }


.l-loading {
  position: fixed;
  z-index: 999999;
}
.l-loading__bg {
    background: #fff;
    display: block;
    height: 100%;
    opacity: 1;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
}
.l-loading__self {
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    z-index: 9999;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loadding 1.7s infinite ease,round 1.7s infinite ease;
    animation: loadding 1.7s infinite ease,round 1.7s infinite ease;
    width: 1em;
    height: 1em;
    margin-top: -.5em;
    margin-left: -.5em;
    color: #76d1c9;
    border-radius: 50%;
    font-size: 50px;
    font-size: 5rem;
}
.complete .l-loading__self {
    display: none;
}
.no-js .l-loading {
    display: none
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  border-radius: inherit; /* !importanté */
  background: #99bf84;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
  &:after {
    background: #e4c06c;
  }
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
  &:after {
    background: #e4c06c;
  }
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
  &:after {
    background: #eba96c;
  }
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
  &:after {
    background: #eba96c;
  }
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
  &:after {
    background: #bf505c;
  }
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
  &:after {
    background: #bf505c;
  }
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
  &:after {
    background: #33b8a3;
  }
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
  &:after {
    background: #33b8a3;
  }
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
  &:after {
    background: #73bf90;
  }
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  &:after {
    background: #73bf90;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@include max-screen(320px) {
  .break-ip5 {
    display: block;
  }
  .nobreak-ip5 {
    display: none;
  }
}