@charset 'UTF-8';

.tab {
	@include min-screen(1024px) {display: none;}
	@include max-screen(767px) {display: none;}
}
.taC {text-align: center;}
.page_top {
	z-index: 99999;
	width: 111px;
	@include max-screen(1024px) { 
		bottom: 13px;
		right: 25px;
	}
	@include max-screen(767px) { 
		width: 90px;
	}
	&.abso {
		position: absolute;
		top: -120px;
		right: 15px;
		@include max-screen(767px) {
			top: -90px;
			right: 0;
		}
	}
	&.fix {
		bottom: 80px;
		right: 15px;
		position: fixed;
		@include max-screen(767px) {
			bottom: 30px;
			right: 10px;
		}
	}
}

#footer {
	background: $green1;
	position: relative;
	z-index: 999;
	color: #fff;
	margin: 0;
	
	.footerInner {
		position: relative;
		&:before {
			position: absolute;content: "";
			top: 0;right: 0;bottom: 0;text-indent: 0;
			width: 100%;height: 100%;
			background: rgba(201,201,201,0.3);
		}
		
		.bg-foot-left {
			position: absolute;content: "";
			top: 0;bottom: 0;text-indent: 0;
			width: 35%;height: 100%;z-index: 8;
			background: $green1;left: 0;
			min-width: 245px;
			@include max-screen(1500px) {
				width: 29%;
			}
			@include max-screen(1300px) {
				width: 25%;
			}
			&:after {
				content: "";
				position: absolute;
				right: -50px;
				top: 50%;
				width: 0;
				height: 0;
			 	margin-top: -124px;
				border-bottom: 124px solid transparent;
				border-left: 50px solid $green1;
				border-top: 124px solid transparent;
			}
		}
	}
	.foot-rows {
		display: table;width: 100%;height: 247px;
	}
	.foot-left {
		padding: 10px 0 10px 0;display: table-cell;vertical-align: middle;
		width: 230px;background: $green1;
		max-width: 230px;position: relative;z-index: 9;
		.f-logo {
			text-align: center;
			margin: 0 -10px;
			li {
				display: inline-block;
				padding: 0 10px;
				&:nth-child(1) {
					width: 100%;
					margin-bottom: 28px;
				}
				// &:nth-child(2), &:nth-child(3) {
				// 	width: 50%;
				// }
			}
		}
	}
	.foot-right {
		display: table-cell;
		color: #fff;vertical-align: middle;
		position: relative;
		padding: 10px 0 10px 17.3%;
		@include max-screen(1200px) {
			padding: 10px 0 10px 9%;
		}
		.foot-right-flex {
			@include flexbox();
			@include flex-wrap(wrap);
			@include justify-content(space-between);
		}
		.f-col {
		}
		h3 {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.3;
			margin-bottom: 15px;
		}
		
		.f-col01 {
			width: 31%;
			.f-logo-app {
				margin-bottom: 10px;
			}
			p {
				font-size: 12px;
				line-height: 1.4;
			}
		}
		.f-col02 {
			width: 65%;
		}
		.f-rows1 {
			@include flexbox();
			@include flex-wrap(wrap);
			@include justify-content(space-between);
		}
		.f-add {
			padding: 0 0 0 70px;
			position: relative;
			&:before {
				position: absolute; content: "";
				left: 0;top: 0;
				background: url(../img/common/f-pin.svg) center center;
				width: 55px;
				height: 55px;
			}
			span {
				margin-bottom: 4px;
				display: block;
			}
		}

		.f-tel {
			padding: 0 0 0 70px;
			position: relative;
			&:before {
				position: absolute; content: "";
				left: 0;top: 0;
				background: url(../img/common/f-tel.svg) center center;
				width: 55px;
				height: 55px;
			}
			a {
				cursor: default;
				pointer-events: none;
			}
			.n-tel {
				font-family: $comfortaa;
				font-size: 30px;
				color: #fff;
				font-weight: bold;
				line-height: 1;
			}
			.f-time {
				color: #fff;
				font-size: 14px;
				display: block;
			}
		}
		p {
			font-size: 14px;
			line-height: 2;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.cp-right {
		background: #fff;
		text-align: center;
		padding: 18px 0;
		p {
			font-size: 14px;
			color: #333333;
			letter-spacing: 0px;
			font-family: $comfortaa;
		}
	}
}
@include SP {
	#footer {
		background: $green1;
		position: relative;
		z-index: 999;
		color: #fff;
		margin: 0;
		.cp-right {
			margin:  0 0 0;
			text-align: center;
		}
		.footerInner {
			.wcm {
			    padding: 0;
			}
			.bg-foot-left {
				position: absolute;content: "";
				top: 195px;left: 0;
				width: 100%;height: 198px;z-index: 8;text-indent: 0;
				background: url(../img/common/f-bg01.png) no-repeat center bottom;
				background-size: 100% 100%;
				height: 68px;width: 100%;
				&:after {
					display: none;
				}
			}
		}
		.foot-left {
			padding: 23px 15px 10px 15px;display: block;
			width: 100%;max-width: 100%;
			.f-logo {
				text-align: center;
				margin: 0 -10px;
				li {
					display: inline-block;
					padding: 0 10px;
					&:nth-child(1) {
						margin-bottom: 25px;
						img {
							width: 77px;
						}
					}
				}
			}
		}
		.foot-right {
			display: block;
			width: 100%;
			margin-bottom: 35px;
			text-align: center;
			padding: 102px 0 13px 0;
			.foot-right-flex,.f-rows1  {
				display: block;
			}
			h3 {
				font-size: 18px;
				line-height: 1.3;
				margin-bottom: 19px;
			}
			
			.f-col01 {
				width: 100%;
				text-align: center;
				margin-bottom: 45px;
				.f-logo-app {
					margin-bottom: 15px;
					img {
						width: 200px;
					}
				}
				p {
					font-size: 12px;
					line-height: 1.4;
				}
			}
			.f-col02 {
				width: 100%;
			}
		
			.f-add {
				padding: 54px 0 0 0;
				margin-bottom: 15px;
				&:before {
					left: 0;top: 0;right: 0;margin: auto;width: 45px;height: 45px;
					background-size: 100% 100%;
				}
				span {
					margin-bottom: 4px;
					display: block;
				}
			}

			.f-tel {
				padding: 54px 0 0 0;
				&:before {
					left: 0;top: 0;right: 0;margin: auto;
					width: 45px;height: 45px;background-size: 100% 100%;
				}
				a {
					cursor: pointer;
					pointer-events: auto ;
				}
				.n-tel {
					
				}
				.f-time {
					margin-top: 6px;
				}
			}
			p {
				font-size: 14px;
				line-height: 1.7;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}